import { useState } from "react";

export default function Counter() {
  const [count, setCount] = useState(0);

  return (
    <div>
      Counter: {count}
      <br />
      <button onClick={() => setCount((c) => c + 1)} disabled={count >= 10}>
        Increment
      </button>
      <button onClick={() => setCount((c) => c - 1)} disabled={count <= 0}>
        Decrement
      </button>
    </div>
  );
}
